import { html, css, LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import './components/servicePost';
import './components/caseStudyPost';
import './components/textHeading';
import './components/blogPost';
import './components/sustainableImage';
import $ from "jquery";
import "slick-carousel";
import "slick-carousel/slick/slick.css";
import "../scss/main.scss";

@customElement('simple-greeting')
export class SimpleGreeting extends LitElement {
  static styles = css`p { color: blue }`;

  @property()
  name = 'Somebody';

  render() {
    return html`<p>Hello, ${this.name}!</p>`;
  }
}

// Mobile menu
(function () {
  const buttons = document.querySelectorAll(".js-open-nav");
  const siteNavigation = document.querySelector(".js-site-navigation");
  const activeClass = "is-active";

  buttons.forEach(button => {
    button.addEventListener("click", (event) => {
      if (siteNavigation.classList.contains(activeClass)) {
        siteNavigation.classList.remove(activeClass);
      }
      else {
        siteNavigation.classList.add(activeClass);
      }
    });
  });

})();

let testimonialOptions: JQuerySlickOptions = {
  slidesToShow: 1,
  mobileFirst: true,
  prevArrow: '<button class="slick-prev"><svg width="22.627" height="37.439" viewBox="0 0 5.987 9.906" xml:space="preserve" xmlns="http://www.w3.org/2000/svg"><path style="fill:none;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;stroke-dasharray:none;stroke-opacity:1" d="m86.39 171.968-3.987-3.986 3.919-3.92" transform="translate(-81.403 -163.062)"/></svg></button>',
  nextArrow: '<button class="slick-next"><svg width="22.627" height="37.439" viewBox="0 0 5.987 9.906" xml:space="preserve" xmlns="http://www.w3.org/2000/svg"><path style="fill:none;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;stroke-dasharray:none;stroke-opacity:1" d="m87.47 163.893 3.987 3.987-3.919 3.92" transform="translate(-86.47 -162.893)"/></svg></button>',
  arrows: false,
  appendDots: ".js-testimonial-dots",
  dots: true,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        centerMode: true,
        arrows: true
      }
    }
  ]
};

$(".js-testimonial-slider").slick(testimonialOptions);

let blogOptions: JQuerySlickOptions = {
  slidesToShow: 1,
  mobileFirst: true,
  arrows: false,
  dots: true,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2
      }
    },
    {
      breakpoint: 1024,
      settings: "unslick"
    }
  ]
};

$(".js-blog-slider").slick(blogOptions);

$(".js-slider").slick({
  slidesToShow: 1,
  mobileFirst: true,
  arrows: false,
  dots: true,
  prevArrow: '<button class="slick-prev"><svg width="22.627" height="37.439" viewBox="0 0 5.987 9.906" xml:space="preserve" xmlns="http://www.w3.org/2000/svg"><path style="fill:none;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;stroke-dasharray:none;stroke-opacity:1" d="m86.39 171.968-3.987-3.986 3.919-3.92" transform="translate(-81.403 -163.062)"/></svg></button>',
  nextArrow: '<button class="slick-next"><svg width="22.627" height="37.439" viewBox="0 0 5.987 9.906" xml:space="preserve" xmlns="http://www.w3.org/2000/svg"><path style="fill:none;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;stroke-dasharray:none;stroke-opacity:1" d="m87.47 163.893 3.987 3.987-3.919 3.92" transform="translate(-86.47 -162.893)"/></svg></button>',
  responsive: [
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
        dots: false,
        arrows: true
      }
    },
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        dots: false,
        arrows: true
      }
    }
  ]
});

