import { html, css, LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';

@customElement('sustainable-image')
export class SustainableImage extends LitElement {
    static styles = css`
        img{
            display: block;
            max-width: 100%;
        }
        div{
            background-color: var(--dark-grey);
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .leaf{
            height: 50px;
        }
    `;

    @property()
    sustainable = 'false';

    @property()
    src = '';

    @property()
    alt = '';

    render() {
        if (this.sustainable === 'true') {
            return html`
                <div part="sustainable-image">
                <svg class="leaf" xmlns="http://www.w3.org/2000/svg" xml:space="preserve" width="232.021" height="320.305" viewBox="0 0 61.389 84.747"><path d="m78.646 185.195-5.032 1.677c12.828-54.252 25.927-34.234 37.743-62.541-10.811 20.271-31.377 12.22-38.584 46.041-1.095-.43-6.294-11.29-4.12-24.554 3.815-23.292 24.086-28.113 38.515-34.664 3.781-1.717 10.27-4.257 12.568-8.581.97-1.826 13.244 18.244 8.379 41.218-4.865 22.974-28.38 29.867-37.435 30.002-2.553.038-5.74-.116-8.082-.466-2.319 6.044-3.952 11.868-3.952 11.868z" style="fill:#000;fill-opacity:1;stroke:#000;stroke-width:.264583" transform="translate(-67.994 -102.325)"/></svg>
                </div>
            `;
        } else {
            return html`
                <img part="sustainable-image" src="${this.src}"
                     alt="${this.alt}"
                     loading="lazy" />
            `;
        }
    }
}