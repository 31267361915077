import { html, css, LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';

@customElement('case-study-post')
export class CaseStudyPost extends LitElement {
    static styles = css`
        .post {
            text-decoration: none;
            display: block;
            margin-left: 1em;
            border-radius: var(--standard-radius);
            max-width: 370px;
        }
        
        .post--drop-shadow{
            box-shadow: var(--drop-shadow);
        }

        .post__heading {
            background-color: hsl(var(--twc-brand-secondary));
            color: var(--pure-white);
            border-radius: 0.2em;
            padding: 0.5em;
            line-height: 1em;
            margin: 0px 1em 1em -0.5em;
            font-size: 2.2em;
        }

        .post__image {
            max-width: 100%;
            grid-row-start: 1;
            grid-column-start: 1;
            border-radius: var(--standard-radius);
            aspect-ratio: 9 / 10;
            object-fit: cover;
            width: 100%;
        }

        .post__caption {
            grid-row-start: 1;
            grid-column-start: 1;
            align-self: end;
        }

        .post__figure {
            margin: 0;
            display: grid;
            grid-template-columns: 1fr;
            grid-template-rows: 1fr;
        }

        .post__footer {
            padding: 1em;
            color: hsl(var(--twc-brand-primary));
            background-color: var(--pure-white);
            border-bottom-left-radius: var(--standard-radius);
            border-bottom-right-radius: var(--standard-radius);
        }
        
        .post--sustainable .post__image{
            background-color: var(--dark-grey);
        }
        
        .post--sustainable .post__heading{
            background-color: var(--medium-grey);
        }
        .post--sustainable{
            background-color: var(--light-grey);
        }
        .post--sustainable .post__footer{
            color: var(--dark-grey);
            border-bottom-left-radius: var(--standard-radius);
            border-bottom-right-radius: var(--standard-radius);
        }
    `;

    @property()
    dropShadow: string = 'false';

    @property()
    imageAltText = "post image";

    @property()
    url = "#"

    @property()
    linkText = 'Start your third sector digital transformation journey';

    @property()
    imageUrl = "https://images.pexels.com/photos/20787/pexels-photo.jpg?auto=compress&cs=tinysrgb&h=350";

    @property()
    heading = 'Digital Transformation';

    @property()
    sustainable: string = 'false';

    showSustainable() {
        return this.sustainable === 'true' ? "post--sustainable" : "";
    }

    showDropShadow() {
        return this.dropShadow === 'true' ? "post--drop-shadow" : "";
    }

    show

    getImage() {
        if (this.sustainable === 'true') {
            return html`
                <div class="post__image"></div>
            `;
        } else {
            return html`
                <img src="${this.imageUrl}"
                     alt="${this.imageAltText}"
                     class="post__image"
                     loading="lazy"/>
            `;
        }
    }
    render() {
        return html`
            <a href="${this.url}" class="post ${this.showDropShadow()} ${this.showSustainable()}">
                <figure class="post__figure">
                    ${this.getImage()}
                    <figcaption class="post__caption">
                        <h2 class="post__heading">${this.heading}</h2>
                    </figcaption>
                </figure>
                <footer class="post__footer">
                    ${this.linkText}
                </footer>
            </a>
        `;
    }
}