import { html, css, LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { styleMap } from 'lit/directives/style-map.js';

@customElement('service-post')
export class ServicePost extends LitElement {
    static styles = css`
        .post {
            border-radius: var(--standard-radius);
            color: var(--pure-white);
            max-width: 370px;
            margin-left: 1em;
            display: block;
            text-decoration: none;
            background-color: hsl(var(--twc-brand-primary));
        }

        .post--light {
            background-color: var(--pure-white);
        }

        .post--light .post__footer {
            color: hsl(var(--twc-brand-primary));
        }

        .post--drop-shadow {
            box-shadow: var(--drop-shadow);
        }

        .post__image {
            max-width: 100%;
            display: block;
            position: relative;
            left: -1em;
            border-radius: 0.7em 0.2em 0.2em 0;
            aspect-ratio: 47 / 20;
            object-fit: cover;
            width: 100%;
        }

        .post__arrow {
            fill: hsl(var(--twc-brand-secondary));
            flex-shrink: 0;
        }

        .post__figure {
            background-color: hsl(var(--twc-brand-secondary));
            padding: 2em 0 0;
            margin: 0;
            border-radius: 0.4em;
        }

        .post__caption {
            padding: 1.5em;
        }

        .post__footer {
            padding: 1.5em;
            display: flex;
            align-items: center;
            justify-content: space-between;
            color: var(--pure-white);
        }
        
        .post__heading {
            /* at desktop */
            font-size: 1.5em;
            line-height: 1em;
        }

        .post__heading {
            margin: 0;
            word-break: break-word;
        }

        .post__image-wrapper {
            padding-right: 1em;
            position: relative;
        }

        .post__image-triangle {
            position: absolute;
            bottom: -1em;
            left: -1em;
            border-width: 0.5em;
            border-style: solid;
            border-left-color: transparent;
            border-bottom-color: transparent;
        }

        .post--sustainable,
        .post--sustainable .post__figure {
            background-color: var(--medium-grey);
            color: var(--dark-grey)
        }
        .post--sustainable .post__footer{
            background-color: var(--light-grey);
            color: var(--dark-grey);
            border-bottom-left-radius: var(--standard-radius);
            border-bottom-right-radius: var(--standard-radius);
        }
        .post--sustainable .post__arrow{
            fill: var(--dark-grey);
        }
        .post--sustainable .post__image{
            background-color: var(--dark-grey);
        }
    `;

    @property()
    heading = 'Digital Transformation';

    @property()
    linkText = 'Start your third sector digital transformation journey';

    @property()
    triangleColor = "#000";

    @property()
    imageUrl = "https://images.pexels.com/photos/20787/pexels-photo.jpg?auto=compress&cs=tinysrgb&h=350";

    @property()
    imageAltText = "post image";

    @property()
    url = "#"

    @property()
    dropShadow: string = 'false';

    @property()
    lightBackground: string = 'false';

    @property()
    sustainable: string = 'false';

    showDropShadow() {
        return this.dropShadow === 'true' ? "post--drop-shadow" : "";
    }

    showLightBackground() {
        return this.lightBackground === 'true' ? "post--light" : "";
    }

    showSustainable() {
        return this.sustainable === 'true' ? "post--sustainable" : "";
    }

    getTriangleColor() {
        return {
            borderTopColor: this.sustainable === 'true' ? 'var(--dark-grey)' : this.triangleColor,
            borderRightColor: this.sustainable === 'true' ? 'var(--dark-grey)' : this.triangleColor
        };
    }

    getImage() {
        if (this.sustainable === 'true') {
            return html`
                <div class="post__image"></div>
            `;
        } else {
            return html`
                <img src="${this.imageUrl}"
                     alt="${this.imageAltText}"
                     class="post__image"
                     loading="lazy"/>
            `;
        }
    }

    render() {
        return html`
        <a href="${this.url}" class="post ${this.showDropShadow()} ${this.showLightBackground()} ${this.showSustainable()}">
            <figure class="post__figure">
                <div class="post__image-wrapper">
                    ${this.getImage()}
                    <span class="post__image-triangle" style="${styleMap(this.getTriangleColor())}"></span>
                </div>
                <figcaption class="post__caption">
                    <h2 class="post__heading">${this.heading}</h2>
                </figcaption>
            </figure>
            <footer class="post__footer">
                ${this.linkText}
                <svg xmlns="http://www.w3.org/2000/svg" class="post__arrow" height="40" width="40" viewBox="0 -960 960 960">
                    <path d="m560-240-56-58 142-142H160v-80h486L504-662l56-58 240 240-240 240Z"/></svg>
            </footer>
        </a>
        `;
    }
}
