import { html, css, LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';

@customElement('text-heading')
export class TextHeading extends LitElement {
    static styles = css`
        header{
            border-left: 5px solid hsl(var(--twc-brand-secondary));
            padding-left: 0.5em;
            line-height: 1;
        }
        :host(.color-brand-primary) header{
            border-color: hsl(var(--twc-brand-primary));
        }
    `;

    render() {
        return html`
        <header>
            <slot></slot>
        </header>
        `;
    }
}