import { html, css, LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import './textHeading';

@customElement('blog-post')
export class BlogPost extends LitElement {
    static styles = css`
        .post__image,
        .post{
            border-radius: var(--standard-radius);
            text-decoration: none;
        }

        .post__image{
            max-width: 100%;
            width: 100%;
            object-fit: cover;
        }
        .post__copy,
        .post__figure{
            margin: 0;
        }
        .post{
            background-color: hsl(var(--twc-brand-primary));
            display: block;
            max-width: 370px;
        }
        .post__caption{
            padding: 1em;
            color: var(--pure-white);
        }
        .post__heading{
            margin: 0 0 0.5em;
        }
        .post--sustainable .post__image{
            background-color: var(--dark-grey);
        }
        
        .post--sustainable .post__caption{
            background-color: var(--light-grey);
            border-bottom-left-radius: var(--standard-radius);
            border-bottom-right-radius: var(--standard-radius);
            color: var(--dark-grey);
        }

        .post--light{
            background-color: var(--pure-white);
        }

        .post--light .post__caption{
            color: hsl(var(--twc-brand-primary));
        }
    `;

    @property()
    index = '2';

    @property()
    url = "#";

    @property()
    heading = 'Digital Transformation';

    @property()
    text = "Insert short blog description or first few lines of the blog/article here...";

    @property()
    imageAltText = "post image";

    @property()
    imageUrl = "https://images.pexels.com/photos/20787/pexels-photo.jpg?auto=compress&cs=tinysrgb&h=350";

    @property()
    sustainable: string = 'false';

    @property()
    lightBackground: string = 'false';

    showLightBackground() {
        return this.lightBackground === 'true' ? "post--light" : "";
    }

    showSustainable() {
        return this.sustainable === 'true' ? "post--sustainable" : "";
    }

    getRatio() {
        if (this.index === '1') {
            return 'aspect-ratio: 281 / 278;';
        }
        else {
            return 'aspect-ratio: 88 / 43;';
        }
    }
    getImage() {
        if (this.sustainable === 'true') {
            return html`
                <div class="post__image" style="${this.getRatio()}"></div>
            `;
        } else {
            return html`
                <img src="${this.imageUrl}"
                     alt="${this.imageAltText}"
                     class="post__image"
                     loading="lazy"
                     style="${this.getRatio()}" />
            `;
        }
    }

    getHeadingColor() {
        return this.sustainable === 'true' ? "var(--dark-grey)" : "";
    }

    render() {
        return html`
        <a href="${this.url}" class="post ${this.showSustainable()} ${this.showLightBackground()}">
            <figure class="post__figure">
                ${this.getImage()}
                <figcaption class="post__caption">
                    <text-heading color="${this.getHeadingColor()}">
                        <h3 class="post__heading">${this.heading}</h3>
                        <p class="post__copy">${this.text}</p>
                    </text-heading>
                </figcaption>
            </figure>
        </a>
        `;
    }
}